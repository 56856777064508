import { Injectable } from "@angular/core";
import { Platform } from "@ionic/angular";
import { ADALModel } from "./adal-model";
import { ADALConfig } from "./adal-config";
import { MsalService } from "@azure/msal-angular";
import { AuthenticationResult } from "@azure/msal-browser";
import { environment } from "src/environments/environment";

declare let Microsoft: any;

@Injectable({
  providedIn: "root",
})
export class ADALProvider {
  private isInited: boolean = false;
  private adalConfig: ADALConfig;
  public model: any;
  private adToken: string;
  private _upn: string;
  public set upn(value: string) {
    localStorage.setItem("UPN", value);
    this._upn = value;
  }
  public get upn(): string {
    if (this._upn) {
      return this._upn;
    } else {
      return localStorage.getItem("UPN");
    }
  }
  constructor(
    private _msalService: MsalService,
    private platform: Platform //private device: Device, //private appVersion: AppVersion, //private appAvailability: AppAvailability
  ) {
    platform.ready().then(async () => {
      await this._init();
    });
  }

  msalLogin(force: boolean = false): Promise<ADALModel> {
    return new Promise<ADALModel>((resolve, reject) => {
      this.platform
        .ready()
        .then(async () => {
          const model = new ADALModel();
          /**
           * If no active account set but there are accounts signed in, sets first account to active account
           * To use active account set here, subscribe to inProgress$ first in your component
           * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
           */
          let activeAccount = this._msalService.instance.getActiveAccount();
          const accessTokenRequest = {
            scopes: ["openid", "offline_access", environment.scope],
            activeAccount,
          };
          if (activeAccount != null && accessTokenRequest) {
           await this._msalService.instance
              .acquireTokenSilent(accessTokenRequest)
              .then((response: AuthenticationResult) => {
                model.Token = response.accessToken;
                localStorage.setItem('Token',response.accessToken);
                this.adToken = response.accessToken;
                model.ExpiresOn = response.expiresOn.getTime().toString();
                const expiresOn = parseInt(localStorage.getItem('ExpiresOn'), 10);
                model.Name = response.account.name;
                this.upn = response.account.username;
                model.ADObject = response;
                resolve(model);
              })
              .catch((err) => {
                // TODO: Handle errors
                console.log(err);
              });
          }
          if (!activeAccount) {
            // redirect anonymous user to login page
            this._msalService.instance.loginRedirect();
          }
        })
        .catch((e) => reject("Unkown Exception while SSO, " + e));
    });
  }

  private _init(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      (async () => {
        if (!this.isInited) {
          this.isInited = true;
        }
        resolve();
      })();
    });
  }



  public async adalLogout(): Promise<void> {
    try {
      await this.platform.ready();
      await this._msalService.logoutPopup({
        mainWindowRedirectUri: "/",
      });
    } catch (error) {
      throw new Error("Unknown Exception while SSO Logout, " + error);
    }
  }

  clearTokenCache2() {
    try {
      this._msalService.logout();
    } catch (error) {}
  }

  logOut(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      try {
        this._msalService.logoutPopup({
          mainWindowRedirectUri: "/",
        });
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  }
  getUPN(): string {
    let upn = localStorage.getItem('UPN');
    return upn;
  }
  getToken(): string {
    return this.adToken;
  }
}

export interface IMSALResponse {
  token: string;
  account: {
    id: string;
    username: string;
    ADObject: any;
    ExpiresOn: string;
    claims: Array<{
      key:
        | "name"
        | "preferred_username"
        | "nbg"
        | "exp"
        | "aio"
        | "uti"
        | "iss"
        | "kid"
        | "sub"
        | "ver"
        | "aud"
        | "oid"
        | "alg"
        | "typ"
        | "iat"
        | "rh"
        | "tid";
      value: string | number;
    }>;
  };
}
