// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiEndpoint: 'https://turnovernotes-api-test-cvx.azurewebsites.net/api',
  appInsights: {
    instrumentationKey : '96011431-97c5-4871-9357-20790ef52053'
  },
  LogonAzureAPI:
    'https://100017272.auth.konycloud.com/login?provider=FWETurnaroundDev',
  AppKey: 'https://tonote-t101-cvx.vault.azure.net/secrets/ToNotesWebAppKey/2341463af8d440ec8089368cf9b5de14',
  AppSecret: 'https://tonote-t101-cvx.vault.azure.net/secrets/ToNotesWebAppSecret/526113fa66d44a14992df4a4e88e9e4d',
  BaseAPI: 'https://chevronupstream-dev1.konycloud.com/services',
  PushNotificationHubPath: 'mobileturnaround-nh-prod-cvx',

  FCMSenderId: '380548788333',
  TCOInstanceId: ['5'],

  adalConfig: {
    tenant: 'chevron.onmicrosoft.com',
    clientId: 'a418d6a9-0215-4eb5-a8fa-95d8d4646794',
    //redirectURL: 'x-msauth-tonotestest://com.chevron.tonotestest',
    redirectURL: 'https://tonotesweb-test-cvx.azurewebsites.net/',
    resource: 'https://graph.windows.net',
    authority: 'https://login.windows.net/chevron.onmicrosoft.com'
  },
  scope: 'https://turnovernotes-test.azure.chevron.com/user_impersonation',
  schemeName: 'com.chevron.mobileturnaround://',
  aiKey: '21cf6e66-9047-4062-959d-e0a16b3fcd11',
  adtionalDbOptions: false
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
