import { Injectable } from '@angular/core';
import { Filter } from '../InternalTypes';
import { NoteEntityManagerService } from './note-entity-manager.service';
import { AppBaseEntityManager } from './AppBaseEntityManager';
import { Constants } from '../../shared/constants';
import { HierarchyDexie } from '../entity/dexie/Hierarchy.dexie';
import { db } from 'src/services/dexie.service';
import { NoteDexie } from '../entity/dexie/Note.dexie';
import { LoadingService } from 'src/services/loading.service';

@Injectable({
  providedIn: 'root',
})
export class HierarchyEntityManagerService extends AppBaseEntityManager {
  protected _entityType = HierarchyDexie;
  dexieName = 'hierarchy';
  constructor(private noteManager: NoteEntityManagerService,private loadingService: LoadingService,) {
    super();
  }

  async getHierarchyForEnvent(
    eventId: number,
    provider: string
  ): Promise<HierarchyDexie[]> {
    try {
      const hierarchy = await db.hierarchy
        .filter((e) => e.EventId == eventId)
        .and((p) => p.Provider === provider)
        .and((f) => f.ForeignEntity != 'Activity')
        .toArray();
      return hierarchy as HierarchyDexie[];
    } catch (error) {
      console.error('Error fetching Hierarchy:', error);
      throw error;
    }
  }

  async getEnvents(): Promise<HierarchyDexie[]> {
    try {
      const event = await db.hierarchy
        .filter((e) => e.ForeignParentId == 0 || e.ForeignParentId == null)
        .toArray();

      return event as HierarchyDexie[];
    } catch (error) {
      console.error('Error fetching Hierarchy:', error);
      throw error;
    }
  }

  saveHierarchies(hierarchies: HierarchyDexie[]): Promise<void> {
    return this.bulkInsert2(hierarchies, this.dexieName);
  }

  async getChildrenForHierarchy(
    hierarchy: HierarchyDexie
  ): Promise<HierarchyDexie[]> {
    try {
      const hierarchies = await db.hierarchy
        .filter(
          (h) =>
            h.ForeignParentId == hierarchy.ForeignId &&
            h.Provider === hierarchy.Provider
        )
        .sortBy('ForeignId');
      return hierarchies as HierarchyDexie[];
    } catch (error) {
      console.error('Error fetching Hierarchy:', error);
      throw error;
    }
  }

  async getActivitiesForHierarchy(
    hierarchy: HierarchyDexie
  ): Promise<HierarchyDexie[]> {
    try {
      const heirarch = await db.hierarchy
        .filter((e) => e.ForeignParentId == hierarchy.ForeignId)
        .and((p) => p.Provider == hierarchy.Provider)
        .and((f) => f.ForeignEntity == 'Activity')
        .sortBy('DisplayId');

      return heirarch as HierarchyDexie[];
    } catch (error) {
      console.error('Error fetching Hierarchy:', error);
      throw error;
    }
  }

  async getChildrenForHierarchyWithSelf(
    hierarchy: HierarchyDexie
  ): Promise<HierarchyDexie[]> {
          if (hierarchy && !isNaN(hierarchy.ForeignId)) {
            const hierarchies = await db.hierarchy
              .filter(
                (h) =>
                  h.ForeignParentId == hierarchy.ForeignId &&
                  h.Provider === hierarchy.Provider
              )
              .sortBy('ForeignId');
            hierarchies.unshift(hierarchy);
            return hierarchies.map((e) => new HierarchyDexie(e));
            
          } else {
            return null;
          }
  }

  async getProjectEventByEventId(eventId: number): Promise<HierarchyDexie> {
    try {
      const hierarch = await db.hierarchy
        .filter((e) => e.EventId == eventId)
        .and((f) => f.ForeignEntity === 'Project')
        .first();
      return hierarch as HierarchyDexie;
    } catch (error) {
      console.error('Error fetching Hierarchy:', error);
      throw error;
    }
  }

  async getHierarchyCategories(
    eventId: number,
    provider: string
  ): Promise<string[]> {
        let categories: string[] = [];
        await db.hierarchy
          .filter((e) => e.EventId == eventId)
          .and((p) => p.Provider === provider)
          .distinct()
          .sortBy('Category')
          .then((r) => {
            categories = [...new Set(r.map((item) => item.Category))];   
          });
          return categories;
  }

 async getFilteredHierarchybyNotes(
    filter: Filter,
    eventId?: number,
    offset?: number,
    filteredTags?: Array<string>
  ): Promise<HierarchyDexie[]> {
      try {
        if (filteredTags?.length > 0) {
          filter.tags = filteredTags;
        }
        const notes = await this.noteManager.getFilteredNotes(filter, eventId);
        let hierarchies = [];
        if (notes && notes.length > 0) {
          const noteParentIds = notes?.map((element) => element.ParentId);
          const uniqueHIds = [...new Set(noteParentIds)];
          const itemQuery = db.hierarchy;
          if (uniqueHIds?.length > 0) {
            hierarchies = await itemQuery
              .where('ForeignId')
              .anyOf(uniqueHIds)
              .sortBy('UpdatedDate');
              if (hierarchies) {
                hierarchies = this._evalHierarchies(hierarchies, notes, offset);
              }
              if (hierarchies) {
                hierarchies.sort((a, b) =>
                  Date.parse(a.notes[0].UpdatedDate) <
                  Date.parse(b.notes[0].UpdatedDate)
                    ? 1
                    : -1
                );
                hierarchies = hierarchies.slice(
                  offset,
                  offset + Constants.pagingSize
                );
              }
          }
          if (filter?.hierarchyCategory?.length > 0) {
            // tslint:disable-next-line:quotemark
            const catStatement: string = filter.hierarchyCategory.join("','");
            hierarchies = await itemQuery
              .where('Category')
              .anyOf(catStatement)
              .sortBy('UpdatedDate');
          }
          if (
            filter.search !== '' &&
            filter.search !== null &&
            filter.search !== undefined
          ) {
            // hierarchies = await itemQuery
            //   .where('Name') 
            //   .startsWithAnyOfIgnoreCase(filter.search)
            //   .or('Category')
            //   .startsWithAnyOfIgnoreCase(filter.search)
            //   .toArray();
            const searchTermLower = filter.search.toLowerCase();
            hierarchies = await itemQuery.where('Name')
            .startsWithIgnoreCase(searchTermLower)
            .or('Category')
            .startsWithIgnoreCase(searchTermLower)
            .toArray();
          }
        }
       
        return hierarchies as HierarchyDexie[];
      } catch (error) {
        throw error;
      }
  }

  private _evalHierarchies(
    h: HierarchyDexie[],
    notes: NoteDexie[],
    offset: number
  ) {
    let hierarchies: HierarchyDexie[] = h;
    for (const hierarchy of hierarchies) {
      for (const note of notes) {
        if (hierarchy.ForeignId === note.ParentId) {
          if (!hierarchy.notes) {
            hierarchy.notes = [];
          }
          hierarchy.notes.push(note);
        }
      }
    }
    return hierarchies;
  }

  getNotesCountForHierarchy(
    hierarchy: HierarchyDexie,
    user?: string
  ): Promise<number> {
    return this.noteManager.getNotesCountForHierarchy(hierarchy, user);
  }

  async getHierarchyById(
    id: number,
    eventId: number,
    provider: string
  ): Promise<HierarchyDexie> {
    const h = await db.hierarchy
      .filter(
        (e) =>
          e.ForeignId == id && e.EventId == eventId && e.Provider === provider
      )
      .first();
    return h as HierarchyDexie;
  }
}
