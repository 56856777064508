import { Injectable } from '@angular/core';
import { AppBaseEntityManager } from './AppBaseEntityManager';
import { UserDexie } from '../entity/dexie/User.dexie';
import { db } from 'src/services/dexie.service';

@Injectable({
  providedIn: 'root',
})
export class UserEntityManagerService extends AppBaseEntityManager {
  protected _entityType = UserDexie;
  dexieName = 'user';
  constructor() {
    super();
  }

  saveUsers(users: UserDexie[]): Promise<void> {
    return this.bulkInsert2(users, this.dexieName);
  }

  async getUser(upn: string): Promise<UserDexie> {
    const u = await db.user
      .filter((e) => e.Upn === upn)
      .first();
    return await (u as unknown as Promise<UserDexie>);
  }

  async getUserUpnsWithNotes(eventId) {
    const notes = (
      await db.notes.where('EventId').equals(eventId).toArray()
    ).map((note) => note.CreatedByUPN);
    const users = await db.user.where('Upn').anyOf(notes).toArray();
    return users.map((u) => u.Upn);
  }
}
